// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Link } from 'gatsby'
import OutLink from '@bodhi-project/components/lib/OutLink'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'
import PDFReader from '@bodhi-project/components/lib/PDFReader'





// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Documents and Information',
  nakedPageSlug: 'SCHOOL DOCUMENTS',
  pageAbstract:
    'Choosing the right school for our children is not an easy task. This is why we believe that the first step of the admission process should be to visit our school and get a feel of the school.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */



const pdfStyles = css({
  maxHeight: '600px !important',
  height: '600px !important',

  '& > div': {
    height: '600px !important',
    maxHeight: '600px !important',

    '& > iframe': {
      height: '600px !important',
      maxHeight: '600px !important',
    },
  },
}).toString()


const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <h1 className="mask-h3">MANDATORY SCHOOL DOCUMENTS</h1>
        
        
        <h1 className="mask-h4">Documents and Information</h1>
      
        
      
        <a href="https://www.cisfgjal.school/AffiliationLetter.pdf">1. Copies of Affiliation Letter</a>
        <br/>
        <a href="https://www.cisfgjal.school/TrustCertificate.pdf">2. Copies of Societies/Trust/Company Registration </a>
        <br/>
        <a href="https://www.cisfgjal.school/ObjectionCertificate.pdf">3. Copies of No Objection Certificate (NOC)</a>
        <br/>
        <a href="https://www.cisfgjal.school/Recognitioncertificate.pdf">4. Copies of  Recognition Certificate under RTE Act, 2009</a>
        <br/>
        <a href="https://www.cisfgjal.school/SafetyofSchoolBuilding.pdf">5. Copies of Valid Building Safety Certificate</a>
        <br/>
        <a href="https://www.cisfgjal.school/FireSafetyCeritificate.pdf">6. Copies of Valid Fire Safety Certificate</a>
        <br/>
        <a href="https://www.cisfgjal.school/DEOCertificate.pdf">7. Copies of the DEO Certificate</a>
        <br/>
        <a href="https://www.cisfgjal.school/HealthCertificate.pdf">8. Copies of valid Water, Health and Sanitation Certificate</a>
        <br/>
<br/> 
        <h1 className="mask-h4">Results and Academics </h1>
        <a href="https://www.cisfgjal.school/feesStructure.pdf">1. Fee Structure of the School</a>
        <br/>
        <a href="https://www.cisfgjal.school/academiccalendar.pdf">2. Annual Academic Calendar</a>
        <br/>
        <a href="https://www.cisfgjal.school/smccertificate.pdf">3.List of School Management Committee (SMC)</a>
        <br/>
        <a href="https://www.cisfgjal.school/pta.pdf">4.List of Parents Teachers Association (PTA) Members</a>
        <br/>
        <a href="https://www.cisfgjal.school/result.pdf">5.Last three-year result of the Board Examination as per application</a>
        <br/>
        <br/>

        {/* <a href="https://www.cisfgjal.school/Mandatory.pdf"><strong><u>Mandatory Public Disclosure</u></strong></a> */}
      



      </Col>
      <Col sm={24} md={8}>
        <h2 className="mask-h4">Admissions Enquiry</h2>
        <p>
          For assistance and help or to see our campus, please visit us at:
          <br />
          <i>Urban Estate, Phase II</i>
          <br />
          <i>Jalandhar, 144002</i>
          <br />
          <i>Punjab</i>
          <br />
          <i>India</i>
        </p>
        <p>
          You can also write an email to us at:
          <br />
          <OutLink to="mailto:admissions@cambridgeinnovativejal.school">
            <i>admissions@cambridgeinnovativejal.school</i>
          </OutLink>
        </p>
        <p>
          Or, you can call us at these numbers between 9AM and 2PM Monday to
          Friday:
          <br />
          <OutLink to="tel:0181-4623951">
            <i>0181-4623951</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623952</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623953</i>
          </OutLink>
        </p>
        <p>
          Do you wish to meet us and have a look around? You can request a
          guided tour by contacting us. Please note that the appointment is
          subject to availability.
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
